import { DocumentSeriesAllocationService } from '@/modules/document-series-allocation/document-series-allocation-service';
import Errors from '@/shared/error/errors';

// action types
export const DO_FETCH = 'doFetch';
export const EXPORT_ALLOCATIONS = 'exportAllocations';

// mutation types
export const FETCH_SUCCESS = 'fetchSuccess';
export const SET_CURRENT_PAGE = 'setCurrentPage';
export const SET_FILTERS = 'setFilters';
export const UPDATE_RECORD = 'updateRecord';
export const SET_TOKENS = 'setTokens';
export const SET_SORT = 'setSort';

const state = {
    rows: [],
    total: 0,
    storeFilters: {},
    currentPage: 1,
    uploadAccessToken: '',
    downloadAccessToken: '',
    storeSort: {sortBy: '', sortDesc: false},
};

const getters = {
    rows: (state) => state.rows || [],
    total: (state) => state.total,
    filters: (state) => state.storeFilters,
    currentPage: (state) => state.currentPage,
    uploadAccessToken: (state) => state.uploadAccessToken,
    downloadAccessToken: (state) => state.downloadAccessToken,
    sorting: (state) => state.storeSort,
};

const actions = {
    [DO_FETCH](context, payload) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'documentSeriesAllocation/get', { root: true });
            if (payload?.sort && payload.sort.includes('allocated_series')) {
                const sortOrder = payload.sort.split('allocated_series');
                payload.sort = `${sortOrder[0]}allocated_number`;
            }
            let filters = { params: { ...payload, pagination: true } };
            if (payload && !payload?.sort) filters = { params: { ...payload , sort: '-created_date'} };
            DocumentSeriesAllocationService.list(filters)
                .then(({ headers, data }) => {
                    const uploadAccessToken = headers['x-file-upload-access-token'];
                    const downloadAccessToken = headers['x-file-download-access-token'];
                    context.commit(SET_TOKENS, {
                        uploadAccessToken,
                        downloadAccessToken,
                    });
                    context.commit(FETCH_SUCCESS, {
                        rows: data.data,
                        total: data?.pagination?.total,
                    });
                    resolve();
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'documentSeriesAllocation/get', { root: true });
                });
        });
    },
    [EXPORT_ALLOCATIONS](context, payload) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'documentSeriesAllocationList/exportAllocations', { root: true });

            DocumentSeriesAllocationService.exportAllocations(payload)
                .then((data) => {
                    const blob = new Blob([data.data], {type: 'text/csv'})
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(blob)
                    link.download = 'raport-documente-externe.csv';
                    link.click();
                    URL.revokeObjectURL(link.href);
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'documentSeriesAllocationList/exportAllocations', { root: true });
                });
        });
    }
};

const mutations = {
    [FETCH_SUCCESS](state, payload) {
        state.rows = payload.rows;
        state.total = payload.total;
    },
    [SET_CURRENT_PAGE](state, page) {
        state.currentPage = page;
    },
    [SET_FILTERS](state, { key, value }) {
        state.storeFilters[key] = value;
    },
    [UPDATE_RECORD](state, record) {
        const recordIndex = state.rows.findIndex((el) => el.id === record.id);
        Object.assign(state.rows[recordIndex], { ...record });
    },
    [SET_TOKENS](state, { uploadAccessToken, downloadAccessToken}) {
        state.uploadAccessToken = uploadAccessToken;
        state.downloadAccessToken = downloadAccessToken;
    },
    [SET_SORT](state, value) {
        state.storeSort = value;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
