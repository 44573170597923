import ApiService from '@/shared/services/api.service';

export class DocumentSeriesAllocationService {
    static list(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/allocations', params)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static create(data) {
        return new Promise((resolve, reject) => {
            ApiService.post( '/allocations', { ...data } )
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static update(data) {
        const { id, payload } = data;
        return new Promise((resolve, reject) => {
            ApiService.patch(`/allocations/${id}`, { ...payload })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static async remove(id) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/allocations/${id}`)
                .then(() => resolve())
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static upload(token, files) {
        return new Promise((resolve, reject) => {
           ApiService.post('/files?echo=true', files, {
               'content-type': 'multipart/form-data',
               'x-file-access-token': token,
           })
               .then((response) => resolve(response.data))
               .catch((error) => reject(error));
        });
    }
    static getUsersList() {
        const params = {
            params: {
                group: ['created_by'],
                sort: 'created_by',
                envelope: false,
                limit: 100,
            },
        };
        return new Promise((resolve, reject) => {
            ApiService.query('/allocations', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static getActiveSeries() {
        const params = {
            params: {
                query: 'active eq true',
                limit: 500,
                fields: 'type_id,type_name',
            },
        };
        return new Promise((resolve, reject) => {
            ApiService.query('/document-series', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static listSeriesPublic(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/allocations-public/list-allocations-for-extension', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static searchSeriesPublic(series) {
        return new Promise((resolve, reject) => {
            ApiService.query(`/allocations-public/search-allocation-for-extension?series=${series}`)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static exportAllocations(params) {
        return new Promise((resolve, reject) => {
            ApiService.post('/allocations/export', { ...params })
            .then(async (response) => {
                resolve(response)
            })
            .catch((error) => reject(error));
        })
    }
}
