import Errors from '@/shared/error/errors';
import ApiService from '@/shared/services/api.service';

export const FETCH_DOCUMENT_SERIES = 'fetchDocumentSeries';

export const FETCH_SUCCEEDED = 'fetchSucceeded';
export const SET_CURRENT_PAGE = 'setCurrentPage';
export const SET_FILTERS = 'setFilters';

const state = {
    documentSeries: [],
    total: 0,
    storeFilters: {},
    currentPage: 1,
};

const getters = {
    total: state => state.total,
    documentSeries: state => state.documentSeries,
    filters: (state) => state.storeFilters,
    currentPage: (state) => state.currentPage,
};

const actions = {
    [FETCH_DOCUMENT_SERIES](context, params) {
        context.commit('shared/activateLoading', 'documentSeriesAllocation/fetchDocumentSeries', { root: true });
        return new Promise((resolve) => {
            ApiService.query('document-series', { params })
                .then(({ data }) => {
                    context.commit(FETCH_SUCCEEDED, data);
                    resolve(data);
                })
                .catch(error => {
                    Errors.handle(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'documentSeriesAllocation/fetchDocumentSeries', { root: true });
                });
        });
    },
};

const mutations = {
    [FETCH_SUCCEEDED](state, data) {
        state.documentSeries = data.data;
        state.total = data.pagination.total;
    },
    [SET_CURRENT_PAGE](state, page) {
        state.currentPage = page;
    },
    [SET_FILTERS](state, { key, value }) {
        state.storeFilters[key] = value;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
