import documentSeriesAllocationFromStore from '@/modules/document-series-allocation/store/document-series-allocation-form-store';
import documentSeriesAllocationListStore from '@/modules/document-series-allocation/store/document-series-allocation-list-store';
import documentSeriesDetailsListStore from '@/modules/document-series-allocation/store/document-series-details-list-store';
import documentSeriesAllocationLocationsStore from '@/modules/document-series-allocation/store/document-series-allocation-locations-store';

export default {
    namespaced: true,
    modules: {
        form: documentSeriesAllocationFromStore,
        list: documentSeriesAllocationListStore,
        details: documentSeriesDetailsListStore,
        locations: documentSeriesAllocationLocationsStore,
    },
};
