import ApiService from '@/shared/services/api.service';

const LS_ANCPI_SEARCH_HISTORY_KEY = 'st_atlas_ancpi_parcel_history';

export class AncpiService {

    static searchParcel(data) {
        return new Promise((resolve, reject) => {
            ApiService.post( '/ancpi/parcels-search-requests/', { ...data } )
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => reject(error));
        });
    }

    static getPersistedSearchHistory() {
        const parcelsString = window.localStorage.getItem(LS_ANCPI_SEARCH_HISTORY_KEY);
        return parcelsString ? JSON.parse(parcelsString) : [];
    }

    static persistSearchHistory(parcels) {
        const parcelsString = JSON.stringify(parcels);
        window.localStorage.setItem(LS_ANCPI_SEARCH_HISTORY_KEY, parcelsString);
    }

}
