// action types
export const DO_SET_LOCATIONS = 'doSetLocations';
export const DO_ADD_LOCATION = 'doAddLocation';
export const DO_UPDATE_LOCATION = 'doUpdateLocation';
export const DO_SET_ACTIVE_LOCATION = 'doSetActiveLocation';
export const DO_DELETE_LOCATION = 'doDeleteLocation';

// mutation types
export const SET_LOCATIONS = 'setLocations';
export const SET_ACTIVE_LOCATION = 'setActiveLocation';
export const DELETE_LOCATION = 'deleteLocation';
export const SET_CURRENT_PAGE = 'setCurrentPage';
export const SET_FILTERS = 'setFilters';
export const ADD_LOCATION = 'addLocation'
export const UPDATE_RECORD = 'updateRecord';
export const SET_SORT = 'setSort';

const state = {
    rows: [],
    activeLocation: null,
    total: 0,
    storeFilters: {},
    currentPage: 1,
    uploadAccessToken: '',
    downloadAccessToken: '',
    storeSort: {sortBy: '', sortDesc: false},
};

const getters = {
    rows: (state) => state.rows || [],
    activeLocation: (state) => state.activeLocation,
    total: (state) => state.total,
    filters: (state) => state.storeFilters,
    currentPage: (state) => state.currentPage,
    uploadAccessToken: (state) => state.uploadAccessToken,
    downloadAccessToken: (state) => state.downloadAccessToken,
    sorting: (state) => state.storeSort,
};

const actions = {
    [DO_SET_LOCATIONS](context, locations) {
        context.commit(SET_LOCATIONS, {
            rows: locations,
            total: locations.length,
        });
    },
    [DO_SET_ACTIVE_LOCATION](context, location) {
        context.commit(SET_ACTIVE_LOCATION, location);
    },
    [DO_ADD_LOCATION](context, location) {
        context.commit(SET_ACTIVE_LOCATION, location);
        context.commit(ADD_LOCATION, location);
    },
    [DO_UPDATE_LOCATION](context, location) {
        context.commit(SET_ACTIVE_LOCATION, location);
        context.commit(UPDATE_RECORD, location);
    },
    [DO_DELETE_LOCATION](context, location) {
        context.commit(DELETE_LOCATION, location);
    },
};

const mutations = {
    [SET_LOCATIONS](state, payload) {
        state.rows = payload.rows;
        state.total = payload.total;
    },
    [SET_ACTIVE_LOCATION](state, payload) {
        state.activeLocation = payload;
    },
    [SET_CURRENT_PAGE](state, page) {
        state.currentPage = page;
    },
    [SET_FILTERS](state, { key, value }) {
        state.storeFilters[key] = value;
    },
    [ADD_LOCATION](state, payload) {
        state.rows.push(payload);
    },
    [DELETE_LOCATION](state, payload) {
        state.rows = state.rows.filter(v => v.key != payload.key);
    },
    [UPDATE_RECORD](state, record) {
        const recordIndex = state.rows.findIndex((el) => el.key == record.updateKey);
        delete record['updateKey'];
        Object.assign(state.rows[recordIndex], { ...record });
    },
    [SET_SORT](state, value) {
        state.storeSort = value;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
